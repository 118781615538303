import React from "react"
import Container from "../components/container"
import Seo from "../components/Seo"
import CustomHeader from "../components/customHeader"
import { Disqus } from "gatsby-plugin-disqus"
import SideBarView from "../components/SideBarView"
import { Grid } from "@mui/material"

const Comments = () => {

  return (
    <Container>
      <CustomHeader title={`Unix / Epoch Clock`}/>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={12} md={9} xl={9}>
          <p>
            Use this page for your questions, comments or suggestions.

            Most tools on this website are client-side and use JavaScript, these tools use time & time zone preferences
            of your computer.

            Use EpochConverter Clock to verify that the time on your computer is correct.
          </p>
          <Disqus
            config={{
              /* Replace PAGE_URL with your post's canonical URL variable */
              url: "https://www.epochconverter.io/comments/",
              /* Replace PAGE_IDENTIFIER with your page's unique identifier variable */
              identifier: "unixtimer_comments_section",
              /* Replace PAGE_TITLE with the title of the page */
              title: "Epoch Converter Comments Section"
            }}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={3} xl={3}>
          <SideBarView/>
        </Grid>
      </Grid>

    </Container>
  )
}
export default Comments

export const Head = () => {
  const siteMetadata = {
    description: "Epoch Converter comments & question section",
    description2: "",
    keywordsContent: "clock, epoch, unix, leap, hex time, seconds, milli seconds"
  }
  return (
    <Seo
      title="Epoch Converter Comments Section"
      canonicalUrl={"https://www.epochconverter.io/comments/"}
      siteMetadata={siteMetadata}
    />
  )
}
